import {ExtendedSearchForm} from "./ExtendedSearchForm";
import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {doSearch, updateSavedSearches} from "../../store/thunks";
import {
    resetSearchFilter,
    searchFilterSelectors,
    setConversationSearch,
    setMessageContent,
    setReceipient,
    setSearchFormHidden,
    setSender,
    setSpamAlso,
    setSubject,
    setWithAttachmentOnly
} from "../../store/SearchParametersSlice";
import {Link} from "react-router-dom"
import {withTranslation, WithTranslation} from "react-i18next";
import {Scrollbars} from 'rc-scrollbars';
import {SavedSearchesList} from "./SavedSearchesList";
import {RootState, useAppDispatch} from "../../store/store";
import {SavedSearch} from "../../store/SavedSearchesSlice";
import {BennoPage} from "../../routes/search";
import {useAuth} from "react-oidc-context";
import {ContainerSelection} from "../header/ContainerSelection";
import {SaveSearchDialog} from "./SaveSearchDialog";

export enum SearchFormType {
    NORMAL, EXTENDED, CONVERSATION
}

export const _SearchPanel: React.FC<{
    onSearchClicked: () => void,
    searchForm: SearchFormType,
    toogleShowSavedSearches: (value: boolean) => void,
    activePage: BennoPage,
    savedSearches?: Array<SavedSearch>,
    container?: string
} & WithTranslation> = ({
                            searchForm,
                            t,
                            savedSearches,
                            onSearchClicked,
                            activePage,
                            container
                        }) => {

    const [saveSearchDialogOpen, setSaveSearchDialogOpen] = useState(false)

    const dispatch = useAppDispatch()

    function createDispatch<T>(action: (payload: T | null) => { payload: T | null, type: string }) {
        return (payload: T) => {
            dispatch(action(payload))
        }
    }

    const minutelyChanging = Math.round((new Date().valueOf()) / (1000 * 60));
    const auth = useAuth();
    useEffect(() => {
        dispatch(updateSavedSearches(auth))
    }, [minutelyChanging])

    useEffect(() => {
        document.body.classList.add('b-search')
        return () => {
            document.body.classList.remove('b-search')
        };
    }, [dispatch]);

    const searchFilter = useSelector(searchFilterSelectors.filter)
    let search = (container: string) => dispatch(doSearch({
        container: container,
        searchType: searchForm,
        resetMessages: true
    }))
    let openSaveSearchDialog = () => {
        setSaveSearchDialogOpen(true)
    }

    let resetSearch = () => dispatch(resetSearchFilter())

    return <>
        <SaveSearchDialog isOpen={saveSearchDialogOpen} close={() => {
            setSaveSearchDialogOpen(false)
        }}/>
        <div id='sidebar_form'>
            <Scrollbars style={{width: '100%', height: '100%'}}>
                <div id='sidebar_form_inner'>
                    <button id='search-panel-hide' onClick={evt => {
                        evt.preventDefault()
                        dispatch(setSearchFormHidden(true))
                    }} title={t('SEARCH_PANEL_CLOSE')}>
                        <span className="icon-arrow-left4"/>
                    </button>

                    {activePage === 'queries' &&
                        <div id='label' className='stage_section' style={{display: "block"}}>

                            {/*<div className='stage_head'><span id='label_head'>{t('TXT_STORED')}</span><span*/}
                            {/*    id='label_head_close' onClick={() => setShowSavedSearches(false)}> </span>*/}
                            {/*</div>*/}

                            <div className='stage_content'>
                                <SavedSearchesList searches={savedSearches}/>
                            </div>

                        </div>}
                    {activePage === 'search' && <>

                        <div id="search_form" className='showThis'>
                            <div className='mobile_search_close'><a tabIndex={-1}/></div>
                            {/*
                    <div className='search_load'>
                        <a tabIndex={18}
                        onClick={() => toogleShowSavedSearches(true)}>{t('SEARCH_LOAD_ENTRIES')}</a>
                    </div>
                    */}
                            <ContainerSelection/>
                            {container && <ExtendedSearchForm
                                searches={savedSearches}
                                isConversation={searchForm == SearchFormType.CONVERSATION}
                                setSubject={createDispatch(setSubject)}
                                setSender={createDispatch(setSender)}
                                setReceipient={createDispatch(setReceipient)}
                                setMessageContent={createDispatch(setMessageContent)}
                                setSpamAlso={(value) => dispatch(setSpamAlso(value))}
                                setConversationSearch={(value) => dispatch(setConversationSearch(value))}
                                setWithAttachmentOnly={(value) => dispatch(setWithAttachmentOnly(value))}
                                filter={searchFilter}
                                search={() => {
                                    onSearchClicked()
                                    search(container)
                                }}
                                saveSearch={openSaveSearchDialog} reset={resetSearch}/>}
                        </div>

                        {/*<div id='stat'>*/}
                        {/*    <p id='mails_in_archive'>{t('TXT_TOTAL_MAILS_IN_ARCHIVE')}: <span*/}
                        {/*        id='mails_in_archive_value'>{totalMessages}</span></p>*/}
                        {/*</div>*/}

                        <div id='sidebar_footer'>
                            <ul>
                                <li><Link tabIndex={-1} to='#imprint'>{t('TXT_IMPRINT')}</Link></li>
                                <li><Link tabIndex={-1} to='#privacy'>{t('TXT_PRIVACY')}</Link></li>
                            </ul>
                        </div>
                    </>}
                </div>
            </Scrollbars>
        </div>
    </>
}

export const SearchPanel = withTranslation()(connect((rootState: RootState) => {
    return {
        savedSearches: rootState.searches.searches,
        container: rootState.searchParameters.bennoContainer
    }
})(_SearchPanel))

