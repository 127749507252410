import {CaseReducer, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {MessageHeader} from "../types/MessageHeader";

export interface SearchResultState {
    emails?: MessageHeader[];
    total?: number;
    page?: number;
    pageSize?: number;
    loading: boolean;
    errors: string;
}

const initialState: SearchResultState = {
    loading: false,
    errors: "",
}

const setLoadingReducer: CaseReducer<SearchResultState, PayloadAction<boolean>> = (state, {payload}) => {
    state.loading = payload
}

const setSearchResultReducer: CaseReducer<SearchResultState, PayloadAction<{
    messages: MessageHeader[],
    pageSize: number,
    total: number,
    page: number
}>>
    = (state, {payload}) => {
    state.emails = payload.messages
    state.pageSize = payload.pageSize
    state.page = payload.page
    state.total = payload.total
}

const setErrorReducer: CaseReducer<SearchResultState, PayloadAction<string>> = (state, {payload}) => {
    state.errors = payload
}

const searchResultSlice = createSlice({
    name: "emails",
    initialState,
    reducers: {
        setLoading: setLoadingReducer,
        setErrors: setErrorReducer,
        setSearchResult: setSearchResultReducer
    }
})

export const {setLoading, setErrors, setSearchResult} = searchResultSlice.actions

export const searchResultSelector = (state: SearchResultState) =>
    state.emails

export default searchResultSlice.reducer
