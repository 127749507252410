import React from "react";

const SearchFormField: React.FC<{
    label: string,
    tabIndex: number,
    name: string,
    cssClass: string,
    value: any,
    setValue: (val: any) => void,
    submit: () => void
}> = (props) => {
    return <>
        <label className={'input_label ' + props.cssClass}
               htmlFor={props.name}>{props.label}</label>
        <input className='input_text' id={props.name}
               onKeyPress={event => {
                   if (event.key == 'Enter') {
                       props.submit();
                       return true;
                   } else {
                       return false;
                   }
               }}
               onChange={(evt) => props.setValue(evt.target.value)}
               value={props.value || ''} type='text' tabIndex={props.tabIndex}/>
    </>
}

export default SearchFormField
