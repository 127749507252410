const routes = runtimeEnv.BENNOAPP_ROUTES || {
    'ADMIN_PATH': '/admin',
    'MESSAGE_PATH': '/message',
    'PROFILE_PATH': '/profile',
    'QUERIES_PATH': '/queries',
    'HOME_PATH': '/',
    'SILENT_REFRESH_PATH': '/silent-refresh',
    'POPUP_REDIRECT_PATH': '/popup-redirect',
}

export const ADMIN_PATH = routes.ADMIN_PATH
export const MESSAGE_PATH = routes.MESSAGE_PATH
export const PROFILE_PATH = routes.PROFILE_PATH
export const QUERIES_PATH = routes.QUERIES_PATH
export const HOME_PATH = routes.HOME_PATH

export const SILENT_REFRESH_PATH = routes.SILENT_REFRESH_PATH
export const POPUP_REDIRECT_PATH = routes.POPUP_REDIRECT_PATH
