import {Dialog} from "./dialogs";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {AuthContextProps} from "react-oidc-context";

const _TokenExpiryDialog: React.FC<{ auth: AuthContextProps, close: () => void } & WithTranslation> = ({
                                                                                                           t,
                                                                                                           auth,
                                                                                                           close
                                                                                                       }) => {
    return <Dialog title={t('DIALOG_TOKEN_EXPIRING_TITLE')} buttons={[
        {
            text: t('DIALOG_TOKEN_EXPIRING_STILL_ACTIVE'), onClick: () => {
                auth.signinSilent().then((user) => {
                    if (!user) {
                        auth.revokeTokens()
                    }
                    close()
                })
                return true
            }, isMain: true, class: ''
        },
        {
            text: t('DIALOG_TOKEN_EXPIRING_LOGOUT'), isMain: false, class: '', onClick: () => {
                auth.removeUser()
                return true
            }
        }
    ]} isOpen={true} close={() => {
        auth.signinSilent()
        close()
    }}>
        <div>{t('DIALOG_TOKEN_EXPIRING_MESSAGE')}</div>
    </Dialog>
}

export const TokenExpiryDialog = withTranslation()(_TokenExpiryDialog)