import {configureStore} from "@reduxjs/toolkit"
import searchResultSliceReducer from "./SearchResultSlice";
import searchParametersSliceReducer from "./SearchParametersSlice";
import messageSliceReducer from "./MessageSlice";
import savedSearchesSliceReducer from "./SavedSearchesSlice";
import usersSliceReducer from "./UsersSlice";
import bennoDataSlice from "./BennoDataSlice";
import {useDispatch} from "react-redux";
import thunk from "redux-thunk";
import uiStateSlice from "./UiStateSlice";

export const store = configureStore({
    reducer: {
        searchResult: searchResultSliceReducer,
        searchParameters: searchParametersSliceReducer,
        message: messageSliceReducer,
        searches: savedSearchesSliceReducer,
        users: usersSliceReducer,
        bennoData: bennoDataSlice,
        uiState: uiStateSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({thunk: true}).concat(thunk)
//     devTools: process.env.NODE_ENV !== "development" ? false : true
})

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>
