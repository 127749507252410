import React, {useEffect} from "react"
import {SavedSearch} from "../../store/SavedSearchesSlice"
import {useTranslation} from "react-i18next"
import {deleteSavedSearch, updateSavedSearches} from "../../store/thunks";
import {loadSearchFilter} from "../../store/SearchParametersSlice";
import {HOME_PATH} from "../../routes";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../store/store";
import {useAuth} from "react-oidc-context";

export const SavedSearchesList: React.FC<{ activeSearchId?: string, searches?: Array<SavedSearch> }>
    = ({
           searches,
           activeSearchId
       }) => {

    const auth = useAuth()
    let minutelyChanging = Math.round((new Date().valueOf()) / (1000 * 60));
    useEffect(() => {
        dispatch(updateSavedSearches(auth))
    }, [minutelyChanging])

    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        document.body.classList.add('b-savedsearch')
        return () => {
            document.body.classList.remove('b-savedsearch')
        };
    }, [dispatch]);

    function deleteSearch(searchId: string) {
        dispatch(deleteSavedSearch({searchId}))
    }

    function loadFilter(searchFilter: SavedSearch) {
        dispatch(loadSearchFilter(searchFilter))
    }

    const renderSearchLabel = (search: SavedSearch) => (
        <div key={search.name} className={`saved-search label${activeSearchId === search.id ? ' label_active' : ''}`}
             title={search.name}>
            <a onClick={() => {
                loadFilter(search);
                navigate(HOME_PATH);
            }}>{search.name}</a>
            <span title={`${search.name} ${t('LABEL_DELETE')}`} className='label_delete'
                  onClick={() => deleteSearch(search.id)}/>
        </div>
    );

    return <div id='saved-searches-list'>
        {searches === undefined && <div className='label'>{t('LABEL_LOADING')}</div>}
        {searches?.length === 0 && <div className='label'>{t('LABEL_NO_SAVED_SEARCHES')}</div>}
        {searches?.map(renderSearchLabel)}
    </div>
}
