import {Sidebar} from "../ui/Sidebar";
import {ProfilePanel} from "../ui/ProfilePanel";
import React from "react";
import {useAuth} from "react-oidc-context";

export const ProfilePage = () => {
    const auth = useAuth()

    return <div id='stage'>
        <div id='stage-inner'>
            <div id='panel' className='stage_section search-form-hidden'>
                <Sidebar/>
            </div>
            <div className='stage-right search-form-hidden bg-light-grey'>
                {/*LOGOUT BUTTON*/}
                <div id='profile-overview' className='stage_section'>
                    <ProfilePanel/>
                </div>
            </div>
        </div>
    </div>
}