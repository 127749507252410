"use strict";
exports.__esModule = true;
exports.UserRole = void 0;
var UserRole;
(function (UserRole) {
    UserRole["SYSTEM"] = "SYSTEM";
    UserRole["AUTH"] = "AUTH";
    UserRole["USER"] = "USER";
    UserRole["REVISOR"] = "REVISOR";
    UserRole["ADMIN"] = "ADMIN";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
