import * as React from 'react';
import {RootState, useAppDispatch} from '../../store/store'
import {setBennoContainer} from '../../store/SearchParametersSlice';
import {updateSearch} from '../../store/thunks';
import {connect} from "react-redux";
import {useAuth} from "react-oidc-context";
import {decodeAccessToken} from "../../Secure";
import {useTranslation} from "react-i18next";
import './ContainerSelection.css'
import Select from "react-select";

export const clientId = process.env.REACT_APP_BENNO_AUTH_CLIENTID as string;

const _ContainerSelection: React.FC<{ container?: string }>
    = ({container}) => {
    const t = useTranslation().t
    const dispatch = useAppDispatch()

    const auth = useAuth()

    let containerList: Array<string> = auth.user?.access_token ? decodeAccessToken(auth.user?.access_token)?.containerList : []

    const containerOptions = containerList.map(container => ({label: container, value: container}));
    return containerList.length > 1 ? <div className="container-selection">
        <label className="input_label"><span
            className="icon-database-export"></span>{t('TXT_CONTAINER')}: {container}</label>

        <Select<{ value: string, label: string }> tabIndex={-1}
                                                  className='container-select react-select-control'
                                                  onChange={value => {
                                                      if (value?.value) {
                                                          dispatch(setBennoContainer(value.value))
                                                          dispatch(updateSearch({
                                                              container: value.value,
                                                              resetMessages: true
                                                          }))
                                                      }
                                                  }}
                                                  options={containerOptions}
                                                  name='select_container'
                                                  value={containerOptions.find(c => c.value === container)}/>
    </div> : <></>
}

export const ContainerSelection = connect((rootState: RootState) => {
    return {
        container: rootState.searchParameters.bennoContainer
    }
})(_ContainerSelection)
