import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {BennoSystemInfo} from "../types/BennoSystemInfo";

export interface BennoDataState {
    bennoSystemInfo?: BennoSystemInfo,
    bennoSystemInfoLoading: boolean
}

const initialState: BennoDataState = {
    bennoSystemInfoLoading: false
}

const bennoDataSlice = createSlice({
    name: "bennoData",
    initialState,
    reducers: {
        setBennoSystemInfo: (state, {payload}: PayloadAction<BennoSystemInfo>) => {
            state.bennoSystemInfo = payload
        },
        setBennoSystemInfoLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.bennoSystemInfoLoading = payload
        }
    }
})

export const {setBennoSystemInfo, setBennoSystemInfoLoading} = bennoDataSlice.actions

export default bennoDataSlice.reducer
