import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {SearchFilter, SupportedHeader} from "../types/SearchFilter";
import {SearchFormType} from "../ui/search/SearchPanel";
import {SavedSearch} from "./SavedSearchesSlice";
import {RootState} from "./store";
import {toStamp} from "../utils";
import {SortingValue} from "./thunks";

export interface SearchParametersState {
    bennoContainer?: string;
    filter: SearchFilter;
    page: number;
    pageSize: number;
    sorting: SortingValue;
    searchForm: SearchFormType,
    searchFormHidden: boolean
}

const initialSearchFilter = {
    withAttachmentOnly: false,
    spamAlso: false,
    headers: []
}

const initialState: SearchParametersState = {
    filter: initialSearchFilter,
    searchForm: SearchFormType.EXTENDED,
    sorting: {field: 'SortableDate', ascending: false},
    pageSize: 20,
    page: 1,
    searchFormHidden: false
}

const searchResultSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        setStartDate: (state, {payload}: PayloadAction<Date | null>) => {
            // TODO: update endDate to now if not set
            state.filter.startDate = payload == null ? undefined : toStamp(payload)
        },
        setEndDate: (state, {payload}: PayloadAction<Date | null>) => {
            // TODO: update endDate to now if not set
            state.filter.endDate = payload == null ? undefined : toStamp(payload)
        },
        setSender: (state, {payload}: PayloadAction<string | null>) => {
            // TODO: update endDate to now if not set
            state.filter.sender = payload == null ? undefined : payload
        },
        setReceipient: (state, {payload}: PayloadAction<string | null>) => {
            // TODO: update endDate to now if not set
            state.filter.recipient = payload == null ? undefined : payload
        },
        setSubject: (state, {payload}: PayloadAction<string | null>) => {
            // TODO: update endDate to now if not set
            state.filter.subject = payload == null ? undefined : payload
        },
        setMessageContent: (state, {payload}: PayloadAction<string | null>) => {
            // TODO: update endDate to now if not set
            state.filter.messageContent = payload == null ? undefined : payload
        },
        setSearchTerm: (state, {payload}: PayloadAction<string | null>) => {
            // TODO: update endDate to now if not set
            state.filter.searchTerm = payload == null ? undefined : payload
        },
        setHeader: (state, {payload}: PayloadAction<{ header: SupportedHeader, value: string | undefined }>) => {
            // TODO: update endDate to now if not set
            let header = payload.header
            state.filter.header = {}
            state.filter.header[header] = payload.value
        },
        resetHeaderFilter: (state, {payload}: PayloadAction<{}>) => {
            // TODO: update endDate to now if not set
            state.filter.header = {}
        },
        setConversationSearch: (state, {payload}: PayloadAction<boolean>) => {
            state.filter.conversationSearch = payload
        },
        setWithAttachmentOnly: (state, {payload}: PayloadAction<boolean>) => {
            // TODO: update endDate to now if not set
            state.filter.withAttachmentOnly = payload
        },
        setSpamAlso: (state, {payload}: PayloadAction<boolean>) => {
            // TODO: update endDate to now if not set
            state.filter.spamAlso = payload
        },
        resetSearchFilter: (state, {payload}: PayloadAction<void>) => {
            state.filter = initialSearchFilter
        },
        loadSearchFilter: (state, {payload}: PayloadAction<SavedSearch>) => {
            state.filter = payload.searchFilter
            state.searchForm = payload.searchFilter.conversationSearch ? SearchFormType.CONVERSATION : SearchFormType.EXTENDED
        },
        setSorting: (state, {payload}: PayloadAction<SortingValue>) => {
            // TODO: update endDate to now if not set
            state.sorting = payload
            state.page = 1
        },
        setPage: (state, {payload}: PayloadAction<number>) => {
            // TODO: update endDate to now if not set
            state.page = payload
        },
        setPageSize: (state, {payload}: PayloadAction<number>) => {
            // TODO: update endDate to now if not set
            state.pageSize = payload
            state.page = 1
        },
        setBennoContainer: (state, {payload}: PayloadAction<string>) => {
            // TODO: update endDate to now if not set
            state.bennoContainer = payload
        },
        setSearchForm: (state, {payload}: PayloadAction<SearchFormType>) => {
            // TODO: update endDate to now if not set
            state.searchForm = payload
        },
        setSearchFormHidden: (state, {payload}: PayloadAction<boolean>) => {
            state.searchFormHidden = payload
        },
    }
})

export const {
    setSubject,
    setStartDate,
    setEndDate,
    setSender,
    setReceipient,
    setMessageContent,
    setSpamAlso,
    setWithAttachmentOnly,
    setConversationSearch,
    setHeader,
    resetHeaderFilter,
    setSearchTerm,
    resetSearchFilter,
    loadSearchFilter,
    setPageSize,
    setPage,
    setSorting,
    setBennoContainer,
    setSearchForm,
    setSearchFormHidden
} = searchResultSlice.actions

export default searchResultSlice.reducer

export const searchFilterSelectors = {
    startDate: (state: RootState) => state.searchParameters.filter.startDate,
    endDate: (state: RootState) => state.searchParameters.filter.endDate,
    sender: (state: RootState) => state.searchParameters.filter.endDate,
    recipient: (state: RootState) => state.searchParameters.filter.endDate,
    subject: (state: RootState) => state.searchParameters.filter.endDate,
    messageContent: (state: RootState) => state.searchParameters.filter.messageContent,
    header: (state: RootState) => state.searchParameters.filter.header,
    withAttachmentOnly: (state: RootState) => state.searchParameters.filter.withAttachmentOnly,
    spamAlso: (state: RootState) => state.searchParameters.filter.spamAlso,
    filter: (state: RootState) => state.searchParameters.filter,
}
