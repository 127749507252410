import React, {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import './MessageList.css'
import {useTranslation, withTranslation, WithTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {RootState, useAppDispatch} from '../../store/store'
import {getMessage, gotoMessageRelative} from '../../store/thunks'
import InfiniteScroll from 'react-infinite-scroll-component'
import {MessageHeader} from "../../types/MessageHeader";
import {PulseLoader} from "react-spinners";
import {selectMessageLoadedAndSelectedByUser} from "../../store/MessageSlice";

const MessageListItem: React.FC<WithTranslation & { email: MessageHeader, active: boolean, openMessage: () => void }>
    = ({email, t, active, openMessage}) => {

    const messageDiv = useRef<HTMLDivElement>(null)

    return <div key={email.bennoId} className={'min_mail' + (active ? ' active' : '')} ref={messageDiv}>
        <a tabIndex={-1} onClick={() => {
            openMessage()
        }}>

            <div className='min_mail_date'>
                <span className='min_mail_date_entry'>{moment(email.date).format('L')}</span>
                <span className='min_mail_date_clock'>{moment(email.date).format('LTS')}</span>
            </div>

            <div className='min_mail_text'>

                <div className='min_mail_text_top'>
                    <span className='min_mail_text_from'>{t('MSG_FROM')}:</span> <span
                    className='min_mail_text_from_mail'>{email.fromEmail}</span><br/>
                    <span className='min_mail_text_to'>{t('MSG_TO')}:</span> <span
                    className='min_mail_text_to_mail'>{email.toEmails.join(", ")} </span>
                </div>

                <span className='min_mail_text_subject_label'>{t('MSG_SUBJECT')}:</span>
                <span className='min_mail_text_subject'>{email.subject}</span>
            </div>

            {email.hasAttachment > 0 && <div className='min_mail_text_attachment'></div>}
        </a>
    </div>
}

const MessageListItemWithTranslation = withTranslation()(MessageListItem)

const _MessageList: React.FC<{
    list?: Array<MessageHeader>, selectedMessage?: MessageHeader,
    searchInProgress: boolean,
    page: number, setPage: (page: number) => void,
    total: number,
    infiniteScrollRef: React.RefObject<InfiniteScroll>,
    notSelectedClass: string
}>
    = ({
           list, selectedMessage, searchInProgress,
           page, setPage, total, infiniteScrollRef, notSelectedClass
       }) => {

    const t = useTranslation().t
    const dispatch = useAppDispatch()
    const scrollParentRef = useRef<HTMLDivElement>(null)
    const [contentHeight, setContentHeight] = useState<number | undefined>()

    useEffect(() => {
        function handleResize() {
            scrollParentRef.current && setContentHeight(scrollParentRef.current.clientHeight)
        }

        handleResize()

        window.addEventListener('resize', handleResize)
    })

    const up = 38
    const down = 40

    useEffect(() => {
        if (list && list.length > 0) {
            document.body.classList.add('b-resultlist')
        } else {
            document.body.classList.remove('b-resultlist')
        }
    }, [list]);

    useEffect(() => {
        let listener = (ev: KeyboardEvent) => {
            if (ev.keyCode == up) {
                dispatch(gotoMessageRelative({n: -1}))
            } else if (ev.keyCode == down) {
                dispatch(gotoMessageRelative({n: 1}))
            }
        };
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        };
    }, [list]);

    return <div
        className={'messages-list stage_content' + (searchInProgress ? ' obsolete-list' : '') + notSelectedClass}>
        <div id='overview_content' style={{opacity: 1}}>
            {list !== undefined && list.length === 0 && <>{t('TXT_NO_RESULTS')}</>}
            {list !== undefined && list.length > 0 &&
                <div className='overview_content_list' ref={scrollParentRef}>
                    {contentHeight &&
                        <InfiniteScroll ref={infiniteScrollRef}
                                        dataLength={list.length}
                                        next={() => {
                                            setPage(page + 1)
                                        }}
                                        scrollThreshold={0.7}
                                        height={contentHeight - 80}
                                        hasMore={page * list.length < total}
                                        loader={<PulseLoader className='benno-loader'/>}
                                        endMessage={
                                            <p style={{textAlign: 'center'}}>
                                                <hr/>
                                            </p>
                                        }>
                            {list?.map(email => <MessageListItemWithTranslation key={email.bennoId}
                                                                                email={email}
                                                                                active={selectedMessage?.bennoId == email.bennoId}
                                                                                openMessage={() => {
                                                                                    dispatch(getMessage({
                                                                                        bennoId: email.bennoId,
                                                                                        selectedByUser: true
                                                                                    }))
                                                                                }}/>)}
                        </InfiniteScroll>}
                </div>}
        </div>
    </div>
}

export const MessageList = connect((rootState: RootState) => {
    return {
        notSelectedClass: selectMessageLoadedAndSelectedByUser(rootState.message) ? '' : ' not-selected',
        list: rootState.searchResult.emails,
        selectedMessage: rootState.message.message,
        searchInProgress: rootState.searchResult.loading
    }
})(_MessageList)

