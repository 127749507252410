import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import {RootState} from "../../../store/store";

const _ExtendedSearchButton: React.FC<WithTranslation & {
    submitClass: string, search: () => void,
    reset: () => void,
    searchInProgress: boolean,
    saveSearch: () => void
}>
    = ({saveSearch, reset, t, submitClass, search, searchInProgress}) => {
    return <div className='panel_buttons'>
        <div className={'search_button' + (searchInProgress ? ' search-in-progress' : '')}>
            <a id='store_search_ext'
               tabIndex={17}
               data-tooltip='Alt + L' title={t('SEARCH_STORE')}
               onClick={(evt) => {
                   saveSearch()
               }}
               className='inner_button_action'><span className="icon-database-insert"></span></a>

            <a id='submit_search_ext' tabIndex={16}
               data-tooltip='Alt + S'
               className={'button search_button ' + submitClass + (searchInProgress ? ' search-in-progress' : '')}
               onClick={() => searchInProgress || search()}>{t('SEARCH_SUBMIT')}</a>
        </div>

        <div className='search_button_reset hover_pointer_cursor'>
            <a onClick={(evt) => {
                reset()
            }}><i className="fa fa-undo" aria-hidden="true"></i> {t('SEARCH_RESET')}</a>
        </div>


    </div>
}

export const ExtendedSearchButton = withTranslation()(connect((rootState: RootState) => {
    return {
        searchInProgress: rootState.searchResult.loading
    }
})(_ExtendedSearchButton))
