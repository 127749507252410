import i18n from 'i18next'
import {initReactI18next} from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector'
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/zh-cn';

import deTranslations from './i18n/de.json'
import deLGTranslations from './i18n/de_LG.json'
import cnTranslations from './i18n/cn.json'
import itTranslations from './i18n/it.json'
import plTranslations from './i18n/pl.json'
import enTranslations from './i18n/en.json'
import moment from 'moment';

// the translations
function createTranslationMap(source: Array<any>) {
    const translations = {}
    source.forEach((translation) => {
        const text = translation['translation']
        // @ts-ignore
        translations[translation['key']] = Array.isArray(text) ? text.join("\n") : text
    })
    return {
        translation: translations
    }
}

const resources = {
    de: createTranslationMap(deTranslations),
    de_LG: createTranslationMap(deLGTranslations),
    en: createTranslationMap(enTranslations),
    cn: createTranslationMap(cnTranslations),
    it: createTranslationMap(itTranslations),
    pl: createTranslationMap(plTranslations)
};

// set language from local storage

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'], // Define the order of the detection methods
            lookupLocalStorage: 'i18nextLng', // Key in localStorage where the language is stored
            caches: ['localStorage'] // Specify where to cache the language information
        },
    })

moment.locale(i18n.language);

i18n.on('languageChanged', (lng) => {
    moment.locale(lng);
});

export const bennoI18n = i18n
