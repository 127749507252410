import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ServerPersistedState} from "./common";
import {SearchFilter} from "../types/SearchFilter";

export interface SavedSearch {
    name: string
    id: string
    userId: string
    createdDate: number
    searchFilter: SearchFilter
}

export interface SavedSearchesState extends ServerPersistedState {
    searches?: Array<SavedSearch>
    activeSearchId?: string;
}

const initialState: SavedSearchesState = {
    loading: false,
    errors: ''
}

const savedSearchesSlice = createSlice({
    name: "savedSearches",
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.loading = payload
        },
        setErrors: (state, {payload}: PayloadAction<string>) => {
            state.errors = payload
        },
        setActiveSearchId: (state, {payload}: PayloadAction<string>) => {
            state.activeSearchId = payload
        },
        setSearches: (state, {payload}: PayloadAction<Array<SavedSearch>>) => {
            state.searches = payload
        },
        removeSearch: (state, {payload}: PayloadAction<string>) => {
            state.searches = state.searches?.filter(search => {
                return search.id != payload
            })
        },
        addSearch: (state, {payload}: PayloadAction<SavedSearch>) => {
            state.searches?.push(payload)
        }
    },
})

export const {
    setLoading,
    setErrors,
    setSearches,
    addSearch,
    setActiveSearchId,
    removeSearch
} = savedSearchesSlice.actions

export default savedSearchesSlice.reducer
