import React, {Component, PropsWithChildren, RefObject, ErrorInfo, ReactNode} from "react";
import moment from "moment/moment";
import {Scrollbars} from "rc-scrollbars";
import {MailResult} from "../../types/MailResult";
import {useTranslation} from "react-i18next";
import {MultipartItem} from "../../types/MessageBody";
import './MessageBodyContent.css'
import {bennoBackend} from "../../store/thunks";
import parse from 'html-react-parser';
import {downloadBlob} from "./utils";
import root from 'react-shadow';
import {useSuppressWarnings} from "../../utils";
import DOMPurify from 'dompurify';

const BodyPart: React.FC<PropsWithChildren> = ({children}) => {
    return <>{children}</>
}

const MultiPart: React.FC<{ item: MultipartItem, index: number }> = ({item, index}) => {
    // Suppress render warnings
    useSuppressWarnings()
    if (!item.contentType || item.contentType.indexOf('text/html') === 0) {
        const cleanHtml = DOMPurify.sanitize(item.data);
        return <BodyPart key={index}>
            <root.div>
                <div dangerouslySetInnerHTML={{ __html: cleanHtml }}/>
            </root.div>
            </BodyPart>
    } else if (item.contentType.indexOf('text/plain') === 0) {
        return <BodyPart key={index}>
            <div className='EMail_Body' style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word'
            }}>{item.data}</div>
        </BodyPart>
    } else {
        debugger
        throw new Error('Unknown content type: ' + item.contentType)
    }
}

const downloadAttachment = async (args: { bennoId: string, bennoContainer: string, attachmentId: number }) => {
    return await bennoBackend.getAttachment(args.bennoId, args.bennoContainer, args.attachmentId)
}

export const MessageBodyContent: React.FC<{
    result: MailResult,
    token?: string,
    scrollbarsRef?: RefObject<Scrollbars>
}> = ({result, token, scrollbarsRef}) => {
    const {t} = useTranslation()
    const bodyParts = result.body;

    let contentToRender;

    if (bodyParts.length > 1) {
        const hasHtmlContent = bodyParts.some(item => item.contentType.indexOf('text/html') === 0);
        if (hasHtmlContent) {
            contentToRender = bodyParts.filter(item => item.contentType.indexOf('text/html') === 0);
        } else {
            contentToRender = bodyParts;
        }
    } else {
        contentToRender = bodyParts;
    }

    function getCcEmails(email: MailResult) {
        return email?.ccEmails == undefined ? [] : email?.ccEmails
    }

    const attachments = result.attachments.filter(attachment => attachment.attachmentId !== null && attachment.attachmentId !== undefined);
    return <div id="mailcontent_inner">
        {result.bennoId && <>
            <div id='mailcontent_header'>
                <div id='mailcontent_header_top'>

                    <div id='mailcontent_header_right'>
                                    <span id='EMail_Date'
                                          className='mailcontent_header_date'>{moment(result?.date).format('L')}</span>
                        <span id='EMail_Time'
                              className='mailcontent_header_date'>&nbsp;{moment(result?.date).format('HH:MM')}</span>
                    </div>

                    <div id='mailcontent_header_left'>
                        <span className='mailcontent_header_email_label'>{t('MSG_FROM')}: </span>
                        <span id='EMail_From'
                              className='mailcontent_header_email'>{result.fromEmail}</span><br/>
                        <span className='mailcontent_header_email_label'>{t('MSG_TO')}: </span>
                        <span id='EMail_To'
                              className='mailcontent_header_email'>{result.toEmails.join(' ')}</span><br/>
                        <span className='mailcontent_header_email_label email_cc_line' id='EMail_CcLine'
                              style={{display: getCcEmails(result).length == 0 ? 'none' : 'inline-block'}}>Cc: </span>
                        <span id='EMail_Cc' className='mailcontent_header_email email_cc_line'
                              style={{display: getCcEmails(result).length == 0 ? 'none' : 'inline-block'}}>{getCcEmails(result).join(' ')}</span>
                    </div>

                </div>

                <div id='mailcontent_header_bottom'>
                    <span className='mailcontent_header_email_label'>{t('MSG_SUBJECT')}: </span>
                    <span id='EMail_Subject'>{result?.subject}</span>
                </div>

            </div>
        </>}

        <div id='mailcontent_attachments'
             className={attachments.length > 0 ? 'hasAttachments' : ''}>
            <div id='EMail_Attachments'>
                {attachments
                    .map((attachment) => {
                        return token ? <a key={attachment.name} tabIndex={-1}
                                          className={'attachment_image ' + attachment.contentType}
                                          download={attachment.name}
                                          href={bennoBackend.getAttachmentUrlWithToken(token, attachment.attachmentId)}
                                          title={attachment.name}>{attachment.name}</a> :
                            <a key={attachment.name} tabIndex={-1}
                               className={'attachment_image ' + attachment.contentType}
                               onClick={async () => {
                                   const blob = await downloadAttachment({
                                       bennoId: result.bennoId,
                                       bennoContainer: result.container,
                                       attachmentId: attachment.attachmentId
                                   })
                                   downloadBlob(blob, attachment.name)
                               }}
                               title={attachment.name}>{attachment.name}</a>
                    })}
            </div>
        </div>

        <div id='mailcontent_body' className={result.attachments.length > 0 ? 'hasAttachments' : 'noAttachments'}>
            {scrollbarsRef ? <Scrollbars style={{width: '100%', height: '100%'}} ref={scrollbarsRef}>
                {contentToRender.map((item, index) => <MultiPart key={index} item={item} index={index}/>)}
            </Scrollbars> : contentToRender.map((item, index) => <MultiPart item={item} index={index}/>)}
        </div>

    </div>
}