import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {setHeader} from "../../../store/SearchParametersSlice";
import {RootState} from "../../../store/store";
import {SearchFilter, SupportedHeader} from "../../../types/SearchFilter";

function typedKeys<T extends {}>(o: T): (keyof T)[] {
    return Object.keys(o) as (keyof T)[];
}

const supportedHeadersTranslations: Array<[SupportedHeader, string]> = [
    ['DATE', 'Date'],
    ['IN_REPLY_TO', 'In-Reply-To'],
    ['MESSAGE_ID', 'Message-ID'],
    ['REFERENCES', 'References'],
    ['USER_AGENT', 'User-Agent']
]

const HeaderSearchPanel: React.FC<WithTranslation & { filter: SearchFilter }> = ({t, filter}) => {
    const dispatch = useDispatch()

    const headerName: SupportedHeader = filter.header && Object.keys(filter.header).length > 0 ? typedKeys(filter.header)[0] : 'DATE'
    const headerValue = filter.header && headerName ? filter.header!![headerName] : ''

    return <>
        <label className='input_label label_ex_mail-header'
               htmlFor='select-header-filter'>{t('SEARCH_MAILHEADER')}</label>
        <select name='ext[headerName]'
                className='input_select' id='select-header-filter'
                value={headerName}
                onChange={evt => {
                    dispatch(setHeader({header: evt.target.value as SupportedHeader, value: ''}))
                }}
                tabIndex={7}>
            {supportedHeadersTranslations.map(([header, translation]) => (
                <option key={header} value={header}>{t(translation)}</option>
            ))}
        </select>
        <input className='input_text'
               id='mail-header2'
               value={headerValue}
               onChange={(evt) => {
                   dispatch(setHeader({header: headerName, value: evt.target.value}))
               }}
               type='text'
               tabIndex={8}/>
    </>
}

export default withTranslation()(connect((rootState: RootState) => {
        return {
            filter: rootState.searchParameters.filter
        }
    }
)(HeaderSearchPanel))
