import React from "react"
import App from "./App"
import {Action, ThunkAction} from "@reduxjs/toolkit"
import reportWebVitals from './reportWebVitals'
import './ui/i18n'
import "react-datepicker/dist/react-datepicker.css"
import {RootState} from "./store/store";
import {createRoot} from "react-dom/client";

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

createRoot(document.getElementById("root")).render(<App/>)

reportWebVitals()
