import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {connect} from "react-redux";
import {getMessageWithToken} from "../../store/thunks";
import {MailResult} from "../../types/MailResult";
import {RootState, useAppDispatch} from "../../store/store";
import {MessageContent} from "./MessageContent";

export const MessageOnlyPanelComponent: React.FC<{ message?: MailResult }> = ({message}) => {
    const {token} = useParams<{ token: string }>()

    const [messageLoadingStarted, setMessageLoadingStarted] = useState(false)
    const dispatch = useAppDispatch()

    if (!messageLoadingStarted) {
        dispatch(getMessageWithToken(token!!))
        setMessageLoadingStarted(true)
    }

    if (!message) {
        return <div>LOADING....</div>
    }

    return <MessageContent showImages={true} message={message}
                           loading={false}
                           token={token!!}
                           errors={''}/>
}

export const MessageOnlyPanel = connect((state: RootState) => {
    return {
        message: state.message.message
    }
})(MessageOnlyPanelComponent)
