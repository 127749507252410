import React, {useEffect} from "react";

export function randomString(length: number) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function toStamp(date: Date) {
    return date.valueOf() / 1000
}

export interface WithDefinedValueOptions {
    defaultValue?: any; // Default value to use if the prop is undefined
}

export function withDefinedContainer<T extends { bennoContainer: string }>(
    Component: React.ComponentType<T>,
    options?: WithDefinedValueOptions
) {
    const WrappedComponent: React.FC<Omit<T, 'bennoContainer'> & { bennoContainer?: string }> = (props) => {
        const container = props.bennoContainer

        if (container === undefined) {
            debugger
            return <div></div>
        }

        const newProps = {...props, bennoContainer: container};

        return <Component {...(newProps as T)} />
    }

    WrappedComponent.displayName = `withDefinedContainer(${Component.displayName || Component.name || 'Component'})`;

    return WrappedComponent;
}

export function useSuppressWarnings() {
    useEffect(() => {
        const originalWarn = console.warn;
        return () => {
            console.warn = originalWarn;
        };
    }, []);
}