import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

const AttachmentSearchPanel: React.FC<WithTranslation & {
    withAttachmentOnly: boolean,
    onChange: (value: boolean) => void
}> = (props) => {

    {/*
    return <div className='input_checkbox_wrapper'>
        <label
            className='input_label label_ex_anhang'
            htmlFor='isAttachment'>{props.t('SEARCH_ATTACHMENT')}</label>
        <input className='input_checkbox'
               id='isAttachment'
               name='ext[isAttachment]'
               type='checkbox'
               onChange={event => {props.onChange(event.target.checked)}}
               checked={props.withAttachmentOnly}
               tabIndex={9} value='on'/>
    </div>
*/
    }

    return <div className='pretty p-switch p-fill pretty-custom'>
        <input
            id='isAttachment'
            name='ext[isAttachment]'
            type='checkbox'
            onChange={event => {
                props.onChange(event.target.checked)
            }}
            checked={props.withAttachmentOnly}
            tabIndex={9} value='on'/>
        <div className="state">
            <label htmlFor='isAttachment'><span className="icon-bug2"></span> {props.t('SEARCH_ATTACHMENT')}</label>
        </div>
    </div>
}

export default withTranslation()(AttachmentSearchPanel)
