import {setErrors, setLoading, setSearchResult} from "./SearchResultSlice"
import {
    clearPermaToken,
    setErrors as setMessageErrors,
    setLoading as setMessageLoading,
    setMessageDetails,
    setPermaToken
} from "./MessageSlice"
import {
    replaceUser,
    setCurrentUser,
    setErrors as setUsersErrors,
    setLoading as setUsersLoading,
    setUsers
} from './UsersSlice'
import {User} from "../types/User";
import {ADMIN_PATH} from "../routes";
import {ForwardEmailRequest, SearchResult} from "../backend/BennoBackend";
import {addSearch, removeSearch, setLoading as setLoadingSavedSearches, setSearches} from "./SavedSearchesSlice"
// @ts-ignore
import {NotificationManager} from 'react-notifications'
import {setBennoSystemInfo, setBennoSystemInfoLoading} from "./BennoDataSlice"
import {setBennoContainer, setPage, setSearchForm} from "./SearchParametersSlice"
import {SearchFormType} from "../ui/search/SearchPanel";
import {MailResult} from "../types/MailResult";
import {NavigateFunction} from "react-router-dom";
import {createAsyncThunk, ThunkDispatch} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {AsyncThunk} from "@reduxjs/toolkit/src/createAsyncThunk";
import RestBennoBackend from "../backend/RestBennoBackend";
import {AuthState} from "react-oidc-context";
import {AccessTokenData} from "../Secure";
import {setAccessToken as setAccessTokenAction, setAsyncLoading, setSearchExplicitlyOpened} from "./UiStateSlice";

export const bennoBackend = new RestBennoBackend()

export const deleteSavedSearch = createBennoThunk<{ searchId: string }>("searches/delete",
    async ({searchId}, _, dispatch) => {
        await bennoBackend.deleteSavedSearch(searchId)
        dispatch(removeSearch(searchId))
    }
)

export const saveSearch = createBennoThunk<{ name: string }>("searches/save",
    async ({name}, getState, dispatch) => {
        const filter = getState().searchParameters.filter
        const search = await bennoBackend.addSavedSearch(name, filter)
        dispatch(addSearch(search))
    }
)

export const forwardEmail = createBennoThunk<ForwardEmailRequest>("forward",
    async (request) => {
        await bennoBackend.forwardEmail(request)
        NotificationManager.success('Email forwarded', 'Forward')
    }
)

const HOUR = 60 * 60

export const createPermalink = createBennoThunk<{ id: string }>("permalink",
    async ({id}, getState, dispatch) => {
        dispatch(clearPermaToken())
        let container = getState().searchParameters.bennoContainer;
        if (!container) {
            throw new Error('No container selected')
        }
        const token = await bennoBackend.createPermaToken({
            bennoId: id,
            container: container,
            expiryDate: Math.floor(Date.now() / 1000 + HOUR * 24)
        })
        dispatch(setPermaToken({bennoId: id, permaToken: token.permatoken}))
        // NotificationManager.success('Permalink created', 'Notification')
    }
)

export const updateSavedSearches = createBennoThunk<AuthState>("searches/update",
    async (auth, getState, dispatch) => {
        dispatch(setLoadingSavedSearches(true))
        try {
            const savedSearches = await bennoBackend.getSavedSearches(auth?.user?.profile.sub)
            dispatch(setSearches(savedSearches))
        } finally {
            dispatch(setLoadingSavedSearches(false))
        }
    }
)

type ThunkConfig = {
    state: RootState
}

export function createBennoThunk<T = void, RESULT = void>(actionPrefix: string,
                                                          doStuff: (arg: T, getState: () => RootState, dispatch: ThunkDispatch<any, any, any>) => Promise<RESULT>,
                                                          // AsyncThunkPayloadCreator<RESULT, T, ThunkConfig>,
                                                          onSuccess?: (result: RESULT, dispatch: ThunkDispatch<any, any, any>, arg: T, getState: () => RootState) => void,
                                                          createLoadingAction?: (state: boolean) => {
                                                              payload: boolean,
                                                              type: string
                                                          },
                                                          createErrorAction?: (payload: string) => {
                                                              payload: string,
                                                              type: string
                                                          }): AsyncThunk<RESULT, T, ThunkConfig> {
    return createAsyncThunk<RESULT, T, ThunkConfig>(actionPrefix,
        async (arg: T, {dispatch, getState}) => {
            createLoadingAction && dispatch(createLoadingAction(true))
            dispatch(setAsyncLoading(true))

            const result = doStuff(arg, getState, dispatch)

            result.then((result) => {
                onSuccess && onSuccess(result, dispatch, arg, getState)
            }).catch((error) => {
                createErrorAction && dispatch(createErrorAction(error))
            }).finally(() => {
                createLoadingAction && dispatch(createLoadingAction(false))
                dispatch(setAsyncLoading(false))
            })

            return result
        })
}

export const updateSearch = createAsyncThunk<void, {
    container: string,
    resetMessages: boolean
}, ThunkConfig>("search/update",
    async ({container, resetMessages}, {dispatch, getState}) => {
        // only update search if there already is a search
        let state = getState()
        if (state.searchResult.total && state.searchParameters.searchForm !== undefined) {
            dispatch(doSearch({
                container,
                resetMessages: resetMessages,
                searchType: state.searchParameters.searchForm
            }))
        }
    })

export type SortingValue = { field: string, ascending: boolean }

export const doSearch = createBennoThunk<{
    container: string,
    resetMessages: boolean,
    searchType: SearchFormType
}, SearchResult>("search",
    ({container, searchType, resetMessages}, getState, dispatch) => {
        dispatch(setSearchExplicitlyOpened(false))
        if (resetMessages) {
            dispatch(setPage(1))
        }
        const state: RootState = getState()
        const filter = state.searchParameters.filter
        const searchPage = state.searchParameters.page || 1
        const searchPageSize = state.searchParameters.pageSize
        dispatch(setSearchForm(searchType))
        return bennoBackend.search(container, filter, searchType, searchPageSize, searchPage || 1,
            state.searchParameters.sorting)
    }, (result: SearchResult, dispatch, searchType, getState) => {
        const searchHits = result.result || []
        dispatch(setSearchResult({
            messages: searchType.resetMessages ? searchHits || [] : [
                ...getState().searchResult.emails || [],
                ...searchHits
            ],
            pageSize: searchHits.length, total: result.totalFoundItems, page: result.currentPage
        }))
        searchType.resetMessages && dispatch(getMessage({bennoId: searchHits[0].bennoId, selectedByUser: false}))
    }, (loading: boolean) => {
        return setLoading(loading)
    }, setErrors)

export const getMessage = createBennoThunk<{ bennoId: string, selectedByUser: boolean }, MailResult>("message/get",
    ({bennoId, selectedByUser}, getState) => {
        const bennoContainer = getState().searchParameters.bennoContainer
        if (!bennoContainer) {
            throw new Error('No container selected')
        }
        return bennoBackend.getMessage(bennoContainer, bennoId)
    }, (details: MailResult, dispatch, arg) => {
        dispatch(setMessageDetails({result: details, selectedByUser: arg.selectedByUser}))
    }, setMessageLoading, setMessageErrors)

export const getMessageWithToken = createBennoThunk("message/getWithToken",
    (token: string) => {
        return bennoBackend.getMessageWithToken(token)
    }, (details: MailResult, dispatch) => {
        dispatch(setMessageDetails({result: details, selectedByUser: false}))
    }, setMessageLoading, setMessageErrors)

export const getUsers = createBennoThunk("users/list",
    () => {
        return bennoBackend.getUsers()
    }, (users: Array<User>, dispatch) => {
        dispatch(setUsers(users))
    }, setUsersLoading, setUsersErrors)

export const getUser = createBennoThunk<{ userId: string }, User>("users/get",
    ({userId}) => {
        return bennoBackend.getUser(userId)
    }, (user, dispatch) => {
        dispatch(setCurrentUser(user))
    }, setUsersLoading, setUsersErrors)

export const openUser = createBennoThunk<{
    userId: string,
    userPath: string,
    navigate: NavigateFunction
}, User>("users/open",
    ({userId}) => {
        return bennoBackend.getUser(userId)
    }, (user, dispatch, {userPath, navigate}) => {
        dispatch(setCurrentUser(user))
        navigate(userPath)
    }, setUsersLoading, setUsersErrors)

export const addUser = createBennoThunk<{ user: User, navigate: NavigateFunction }, User>("users/add",
    ({user}) => {
        return bennoBackend.addUser(user)
    }, (user: User, dispatch, {navigate}) => {
        dispatch(setCurrentUser(user))
        navigate(`${ADMIN_PATH}/user/${user.username}`)
    }, setUsersLoading, setUsersErrors)

export const saveUser = createBennoThunk<User, User>("users/save",
    (user: User) => {
        return bennoBackend.saveUser(user)
    }, (user: User, dispatch) => {
        dispatch(replaceUser(user))
    }, setUsersLoading, setUsersErrors)

// export const getSavedSearches = createBennoThunk<User, User>(
//     (user: User) => {
//         return bennoBackend.addUser(user)
//     }, (user: User, dispatch) => {
//         dispatch(addUserToStore(user))
//         history.pushState({}, "Message", `${ADMIN_PATH}/user/${user.userId}`)
//     }, setUsersLoading, setUsersErrors)

export const getBennoSystemInfo = createBennoThunk("sysinfo",
    async (_, getState, dispatch) => {
        dispatch(setBennoSystemInfoLoading(true))
        const data = await bennoBackend.getSystemInfo()
        dispatch(setBennoSystemInfo(data))
        dispatch(setBennoSystemInfoLoading(false))
    }
)

export const gotoMessageRelative = createBennoThunk<{ n: number }>("message/goto",
    async ({n}, getState, dispatch) => {
        const list = getState().searchResult.emails
        const selected = getState().message.message

        if (list && selected) {
            const selectedIdx = list.findIndex(m => {
                return m.bennoId == selected.bennoId
            })

            const jumptoIndex = Math.min(Math.max(0, selectedIdx + n), list.length - 1)

            dispatch(getMessage({bennoId: list[jumptoIndex].bennoId, selectedByUser: false}))
        }
    })

export const setAccessToken = createAsyncThunk<void, AccessTokenData, ThunkConfig>(
    'auth/token',
    async (tokenData: AccessTokenData, {dispatch, getState}) => {
        dispatch(setAccessTokenAction(tokenData));

        if (tokenData.containerList.length > 0) {
            // Determine last selected container in WebInterface
            let currentContainer = getState().searchParameters.bennoContainer;
            if (currentContainer) {
                // Set last selected container
                dispatch(setBennoContainer(currentContainer));
            } else {
                // Set first entry in token container list
                dispatch(setBennoContainer(tokenData.containerList[0]));
            }
        }
    }
);

