import {withTranslation, WithTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Dialog} from "../dialogs";
import {connect} from "react-redux";
import {bennoBackend, forwardEmail} from "../../store/thunks";
import {MailResult} from "../../types/MailResult";
import {MESSAGE_PATH} from "../../routes";
import {RootState, useAppDispatch} from "../../store/store";
import {useAuth} from "react-oidc-context";

function validateEmail(email: string): boolean {
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    return !!email.match(pattern)
}

const ForwardMailDialogComponent: React.FC<{
    isOpen: boolean, message: MailResult,
    close: () => void,
    bennoContainer?: string,
    asyncLoading: boolean
} & WithTranslation>
    = ({t, isOpen, message, close, bennoContainer, asyncLoading}) => {

    const dispatch = useAppDispatch()
    const [error, setError] = useState('')
    const auth = useAuth()
    const [forwardTo, setForwardTo] = useState(auth.user?.profile.email || '')

    return bennoContainer && <Dialog isOpen={isOpen} close={close} title={t('BOX_MAILFORWARD')} buttons={[
        {
            text: t('LABEL_OK'), onClick: () => {
                if (validateEmail(forwardTo)) {
                    setError('')
                    setForwardTo('')
                    dispatch(forwardEmail({
                        recipientAddresses: [forwardTo],
                        bennoId: message.bennoId,
                        container: bennoContainer
                    }))
                    return true
                } else {
                    setError(t('MSG_INVALIDEMAIL'))
                    return false
                }
            }, isMain: true, class: ''
        },
        {
            text: t('LABEL_CANCEL'), isMain: false, class: '', onClick: () => {
                setError('')
                setForwardTo('')
                return true
            }
        }
    ]}>
        {t('MSG_RECIPIENT_ADDRESS')}:
        <input tabIndex={-1} name='wl_mailto' id='wl_mailto' type='text' className='input_text'
               style={{border: '1px solid rgb(204, 204, 204)'}} onChange={event => setForwardTo(event.target.value)}
               value={forwardTo}/>
        {error && <p className='validation-error'>{error}</p>}
    </Dialog> || <></>
}

export const ForwardMailDialog = connect((rootState: RootState) => {
    return {
        bennoContainer: rootState.searchParameters.bennoContainer,
        asyncLoading: rootState.uiState.asyncLoading,
    }
})(withTranslation()(ForwardMailDialogComponent))


const PermaLinkDialogComponent: React.FC<{ isOpen: boolean, message?: MailResult, close: () => void } & WithTranslation>
    = ({isOpen, t, message, close}) => {

    let permaLinkInput: HTMLInputElement | null

    function createPermaLink(token: string): string {
        return `${window.location.origin.toString()}${MESSAGE_PATH}/${token}`
    }

    return <Dialog isOpen={isOpen} close={close} title={t('MSG_PERMALINK')} buttons={[
        {text: t('LABEL_OK'), isMain: true, class: 'ui-button-fullwidth'}
    ]}>
        <input readOnly type='text' className='input_text readonly-input' ref={inputEl => (permaLinkInput = inputEl)}
               value={message?.permaToken ? createPermaLink(message?.permaToken) : t('MSG_LOADING')}/>
        <div style={{textAlign: "center"}}>
            <a id='dialog_permalink_copy' className='modal_button' onClick={event => {
                permaLinkInput?.select()
                document.execCommand("copy")
            }
            }>{t('BTN_COPY_TO_CLIPBOARD')}</a>
        </div>
    </Dialog>
}

export const PermaLinkDialog = connect((rootState: RootState) => {
    return {
        message: rootState.message.message
    }
})(withTranslation()(PermaLinkDialogComponent))

export const MessageSourceDialogComponent: React.FC<{
    isOpen: boolean,
    message: MailResult,
    close: () => void
} & WithTranslation>
    = ({isOpen, t, message, close}) => {
    const [textContent, setTextContent] = useState('');

    const downloadMessageSource = async () => {
        const blob = await bennoBackend.getRawMessage(message.container, message.bennoId, false);
        const text = await blob.text();
        setTextContent(text);
    };
    useEffect(() => {
        downloadMessageSource();
    }, [message.bennoId]);

    return <Dialog isOpen={isOpen} close={close} title={t('MSG_SOURCE')} modalStyle={{
        content: {
            width: '80%',
            transform: 'translate(-50%, -38%)',
            // maxHeight: '80%',
            height: '90%',
            display: 'block',
        }
    }}>
        <div style={{
            // overflow: 'auto',
            width: '100%'
        }}>{textContent && <pre>{textContent}</pre>}</div>
    </Dialog>
}

export const MessageSourceDialog = withTranslation()(MessageSourceDialogComponent)