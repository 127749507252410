import {SILENT_REFRESH_PATH} from "./routes";
import {AuthProviderUserManagerProps} from "react-oidc-context";
import {User as OidcUser, UserManager, WebStorageStateStore} from "oidc-client-ts";
import {jwtDecode, JwtPayload} from "jwt-decode";
import {AppDispatch} from "./store/store";
import {setAccessToken} from "./store/thunks";

function decodeJwtToken(token: string): any {
    // Split the JWT token into its three parts (Header, Payload, Signature)
    const parts = token.split('.');

    // Verify the token structure is correct
    if (parts.length !== 3) {
        throw new Error('The token is invalid');
    }

    // The payload is the second part of the token
    const payload = parts[1];

    // Base64Url decode the payload
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    // Parse the JSON payload
    return JSON.parse(jsonPayload);
}

const restEndpoint = runtimeEnv.BENNO_REST_ENDPOINT || process.env.REACT_APP_BENNO_REST_ENDPOINT
const appUrl = runtimeEnv.BENNOAPP_URL || process.env.REACT_APP_BENNOAPP_URL
const authEndpoint = runtimeEnv.BENNO_AUTH_ENDPOINT || process.env.REACT_APP_BENNO_AUTH_ENDPOINT

export const authority = authEndpoint || 'http://127.0.0.1:21090/';
export const clientId = runtimeEnv.BENNO_AUTH_CLIENTID || process.env.REACT_APP_BENNO_AUTH_CLIENTID || 'bennopkce';
let redirectUri = (appUrl || 'http://127.0.0.1:3000/');
// let redirectUri = (appUrl || 'http://127.0.0.1:3000/') + 'callback';
let responseType = 'code';
let scope = 'openid';

export const userManager: UserManager = new UserManager(
    {
        authority: authority,
        client_id: clientId,
        redirect_uri: redirectUri,
        automaticSilentRenew: true,
        silent_redirect_uri: redirectUri + SILENT_REFRESH_PATH,
        scope: scope,
        // response_type: responseType,
        userStore: new WebStorageStateStore({store: window.localStorage})
    })

export const oidcConfig = (dispatch: AppDispatch): AuthProviderUserManagerProps => {
    userManager.events.addUserLoaded(user => {
        dispatch(setAccessToken(decodeAccessToken(user.access_token)))
    })
    return {
        onSigninCallback: async (user: OidcUser | void) => {
            window.history.replaceState(
                {},
                document.title,
                window.location.pathname
            )
        },
        userManager
    }
}

interface KeycloakToken extends JwtPayload {
    'benno-filter': string,
    email: string,
    realm_access: {
        roles: string[]
    },
    resource_access: {
        [key: string]: {
            roles: string[]
        }
    }
}

export type AccessTokenData = {
    containerList: Array<string>,
    roles: Array<string>,
    email: string,
    bennoFilter: string,
    realmManagementRoles?: Array<string>,
    keycloakAdminUrl: string
}

export const decodeAccessToken: (accessToken: string) => AccessTokenData = (accessToken) => {
    const decodedToken = jwtDecode<KeycloakToken>(accessToken);
    let bennoRoles = decodedToken.resource_access[clientId].roles;

    function isContainerRole(r: string) {
        return r.startsWith('CONTAINER-');
    }

    const containerList = bennoRoles.filter(isContainerRole).map(r => r.split('-')[1]);
    const roles = bennoRoles.filter(r => !isContainerRole(r));
    return {
        containerList, roles,
        email: decodedToken.email,
        bennoFilter: decodedToken["benno-filter"],
        realmManagementRoles: decodedToken.resource_access['realm-management']?.roles,
        keycloakAdminUrl: `${new URL(decodedToken['iss']!!).origin}/admin`
    }
}