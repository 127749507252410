import React, {useState} from "react"
import {Dialog} from "../dialogs"
import {saveSearch as saveSearchThunk} from "../../store/thunks"
import {useAppDispatch} from "../../store/store"
import {WithTranslation, withTranslation} from "react-i18next"

const _SaveSearchDialog: React.FC<{ isOpen: boolean, close: () => void } & WithTranslation> = ({isOpen, close, t}) => {

    const dispatch = useAppDispatch()

    let saveSearch = (name: string) => dispatch(saveSearchThunk({name: name}))
    const [searchName, setSearchName] = useState('')
    let searchNameInput: HTMLInputElement | null

    return <Dialog title={t('BOX_STOREQUERY')} buttons={[
        {
            text: t('LABEL_OK'), onClick: () => {
                saveSearch(searchName)
                return true
            }, isMain: true, class: '', active: searchName.length > 0
        },
        {text: t('LABEL_CANCEL'), isMain: false, class: ''}
    ]} isOpen={isOpen}
                   close={close}
                   onAfterOpen={() => searchNameInput?.focus()}>
        <input tabIndex={-1} maxLength={30} type='text' className='input_text search_name_input'
               ref={inputEl => (searchNameInput = inputEl)}
               onKeyDown={evt => {
                   if (evt.key === 'Enter' && searchName.length > 0) {
                       saveSearch(searchName)
                       close()
                   }
               }}
               onChange={evt => setSearchName(evt.target.value)}/>
    </Dialog>
}

export const SaveSearchDialog = withTranslation()(_SaveSearchDialog)