import {withTranslation, WithTranslation} from "react-i18next";
import * as React from "react";
import {useEffect} from "react";
import i18next from "i18next";
import {useAuth, withAuthenticationRequired} from "react-oidc-context";
import {connect} from "react-redux";
import {RootState} from "../store/store";
import {AccessTokenData} from "../Secure";
import './ProfilePanel.css'

const _ProfilePanel: React.FC<WithTranslation & { accessToken?: AccessTokenData }> = ({t, accessToken}) => {

    useEffect(() => {
        i18next.loadResources()
    }, [i18next]);

    let authContextProps = useAuth();
    const user = authContextProps.user?.profile

    return <div id="profile-information" className="shadow-me">
        <span className="header-title">{t('TXT_USERINFO')}</span>
        <table className="table-style-1">
            <tbody>
            <tr>
                <td>{t('TXT_USERNAME')}:</td>
                <td className="table-data"><span>{user?.name}</span></td>
            </tr>
            <tr>
                <td>{t('TXT_LOGONNAME')}:</td>
                <td className="table-data"><span>{user?.preferred_username}</span></td>
            </tr>
            <tr>
                <td>{t('TXT_ARCHIVE')}:</td>
                <td className="table-data"><span>{accessToken?.containerList.join(', ')}</span><br/>
                </td>
            </tr>
            <tr>
                <td>{t('TXT_ROLE')}:</td>
                <td className="table-data"><span>{accessToken?.roles.join(', ')}</span></td>
            </tr>
            <tr>
                <td>{t('TXT_EMAIL_ADDRESS')}:</td>
                <td className="table-data"><span>{accessToken?.email}</span></td>
            </tr>
            <tr>
                <td>{t('TXT_FILTERS')}:</td>
                <td className="table-data">{(accessToken?.bennoFilter as string)?.split(',').map((email, index) => {
                    return <><span key={index}>{email}</span><br/></>
                })}
                </td>
            </tr>
            <tr>
                <td>{t('TXT_LANGUAGE')}:</td>
                <td className="table-data"><select onChange={e => {
                    i18next.changeLanguage(e.currentTarget.value)
                    localStorage.setItem('i18nextLng', e.currentTarget.value)
                }
                } className="form-control input_select language-selector mb-0" value={i18next.language}>
                    {['de', 'de_LG', 'en', 'cn', 'it', 'pl'].map((lang, index) => {
                        return <option key={index} value={lang}>{lang}</option>
                    })}
                </select></td>
            </tr>
            </tbody>
        </table>
    </div>
}

export const ProfilePanel = connect((rootState: RootState) => {
    return {
        accessToken: rootState.uiState.accessToken
    }
})(withTranslation()(withAuthenticationRequired(_ProfilePanel)))
