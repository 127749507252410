import {CaseReducer, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {AccessTokenData} from "../Secure";

export interface UiState {
    asyncLoading: boolean;
    accessToken?: AccessTokenData,
    subnavOpen: boolean,
    searchExplicitlyOpened: boolean
}

const initialState: UiState = {
    asyncLoading: false,
    subnavOpen: false,
    searchExplicitlyOpened: false
}

const setAsyncLoadingReducer: CaseReducer<UiState, PayloadAction<boolean>> = (state, {payload}) => {
    state.asyncLoading = payload
}

const setAccessTokenReducer: CaseReducer<UiState, PayloadAction<AccessTokenData>> = (state, {payload}) => {
    state.accessToken = payload
}

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setAsyncLoading: setAsyncLoadingReducer,
        setAccessToken: setAccessTokenReducer,
        toogleSubnavOpen: state => {
            state.subnavOpen = !state.subnavOpen
        },
        toggleSearchExplicitlyOpened: state => {
            state.searchExplicitlyOpened = !state.searchExplicitlyOpened
        },
        setSearchExplicitlyOpened: (state, {payload}: PayloadAction<boolean>) => {
            state.searchExplicitlyOpened = payload
        }
    }
})

export const {setAsyncLoading, setAccessToken, toogleSubnavOpen,
    toggleSearchExplicitlyOpened,
    setSearchExplicitlyOpened} = uiSlice.actions

export default uiSlice.reducer
