import React, {useEffect, useState} from 'react'
import {connect, Provider} from 'react-redux'
import './App.css'
import {RootState, store, useAppDispatch} from './store/store';
import './css/reset.css'
import './css/bootstrap-grid.min.css'
import './css/font-awesome.css'
import './css/icomoon.css'
import './css/pretty-checkbox.min.css'
import './css/text.css'
import './css/master.css'
import './css/form.css'
import './css/lws.css'
import {createBrowserRouter, LoaderFunctionArgs, RouterProvider, useLoaderData} from "react-router-dom";
import {NotificationContainer} from 'react-notifications'
import {decodeAccessToken, oidcConfig, userManager} from "./Secure";
import {AuthProvider, useAuth, withAuthenticationRequired} from "react-oidc-context";
import {WithTranslation, withTranslation} from "react-i18next";
import {TokenExpiryDialog} from "./ui/TokenExpiryDialog";
import {SearchPage} from "./routes/search";
import {MessageOnlyPanel} from "./ui/message/MessageOnlyPanel";
import {PROFILE_PATH, QUERIES_PATH} from "./routes";
import {ProfilePage} from "./routes/profile";
import 'react-notifications/lib/notifications.css'
import {User} from "oidc-client-ts";
import {setAccessToken} from "./store/thunks";

const AppWrapper = () => {
    return (
        <Provider store={store}>
            <App/>
        </Provider>
    )
}

const _PrivateRoute: React.FC<{ children: React.ReactNode, bennoContainer?: string }> = ({
                                                                                             children,
                                                                                             bennoContainer
                                                                                         }) => {
    const auth = useLoaderData() as User
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!bennoContainer && auth) {
            dispatch(setAccessToken(decodeAccessToken(auth.access_token)))
        }
    }, [bennoContainer, auth])

    // if (auth.isLoading && !auth.isAuthenticated) {
    //     return <div>Loading...</div>
    // }

    // if (auth.isAuthenticated) {
    return <>{children}</>
    // }
    //
    // debugger
    // return <div>Redirect to login</div>
}

const PrivateRoute = withAuthenticationRequired(connect((state: RootState) => {
    return {
        bennoContainer: state.searchParameters.bennoContainer
    }
})(_PrivateRoute))

const authLoader = async function (args: LoaderFunctionArgs<any>) {
    const user = await userManager.getUser()
    return user;
}

const TranslatedLoadedApp: React.FC<WithTranslation> = ({t}) => {
    const dispatch = useAppDispatch()
    const [bennoLoaded, setBennoLoaded] = useState(false)
    const [inactivityLogout, setInactivityLogout] = useState(false)
    // if (!bennoLoaded) {
    //     dispatch(getBennoData())
    //     setBennoLoaded(true)
    // }

    const auth = useAuth();

    useEffect(() => {
        const cleanup1 = auth.events.addUserSignedOut(() => {
            setTimeout(() => {
                debugger
                auth.signinRedirect()
            }, 100)
        })
        // const cleanup2 = auth.events.addAccessTokenExpiring(() => {
        //     setInactivityLogout(true);
        // })
        return () => {
            cleanup1()
            // cleanup2()
        }
    }, [auth.events, auth.signinSilent]);

    if (!auth.isAuthenticated) {
        switch (auth.activeNavigator) {
            case "signinSilent":
                return <div>Signing you in...</div>;
            case "signoutRedirect":
                return <div>Signing you out...</div>;
        }
    }

    if (inactivityLogout) {
        return <TokenExpiryDialog auth={auth} close={() => {
            setInactivityLogout(false)
        }}/>;
    }

    if (auth.error) {
        setTimeout(() => {
            auth.signinRedirect()
        }, 100);

        return <div>Oops... {auth.error.message}</div>;
    }

    const searchPage = <SearchPage/>

    const router = createBrowserRouter([
        {
            path: '/loggedout', element: <></>
        },
        {
            path: '/message/:token', element: <MessageOnlyPanel/>
        },
        {
            path: PROFILE_PATH, loader: authLoader, element: <PrivateRoute><ProfilePage/></PrivateRoute>
        },
        {
            path: '/', loader: authLoader, element: <PrivateRoute>{searchPage}</PrivateRoute>
        },
        {
            path: QUERIES_PATH, loader: authLoader, element: <PrivateRoute>{searchPage}</PrivateRoute>
        }
    ])

    return <RouterProvider router={router}/>
}


const LoadedApp = withTranslation()(TranslatedLoadedApp)

const _App: React.FC<{ asyncLoading: boolean }> = ({asyncLoading}) => {
    const dispatch = useAppDispatch()
    return <div className={'App' + (asyncLoading ? ' async-loading' : '')}>
        <NotificationContainer/>
        <AuthProvider {...oidcConfig(dispatch)}>
            <LoadedApp/>
        </AuthProvider>
    </div>
}

const App = connect((state: RootState) => {
    return {
        asyncLoading: state.uiState.asyncLoading
    }
})(_App)

export default AppWrapper
