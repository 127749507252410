import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {setSearchForm} from "../../../store/SearchParametersSlice";
import {SearchFormType} from "../SearchPanel";
import {useDispatch} from "react-redux";

const ConversationSearchPanel:
    React.FC<WithTranslation & { isConversation: boolean, onChange: (value: boolean) => void }> = (props) => {
    const dispatch = useDispatch()

    return <div className='pretty p-switch p-fill pretty-custom'>
        <input
            id='isConversationSearch'
            name='ext[isConversationSearch]'
            type='checkbox'
            onChange={event => {
                let conversationEnabled = event.target.checked
                props.onChange(conversationEnabled)
                dispatch(setSearchForm(conversationEnabled ? SearchFormType.CONVERSATION : SearchFormType.EXTENDED))
            }}
            checked={props.isConversation}
            value='on'/>
        <div className="state">
            <label htmlFor='conversion-search-toggle'><span
                className="icon-bubbles9"></span> {props.t('SEARCH_CONVERSION')}
            </label>
        </div>
    </div>
}

export default withTranslation()(ConversationSearchPanel)
