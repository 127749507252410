import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import './MassageListControlPanel.css'
import {SortingValue} from "../../store/thunks";
import {connect} from "react-redux";
import {RootState} from "../../store/store";
import {PulseLoader} from "react-spinners";
import {BennoColors} from "../colors";

const sortingOptions: Array<{ value: SortingValue, translationKey: string }> = [
    {value: {field: 'SortableDate', ascending: false}, translationKey: 'SORT_DATEDESC'},
    {value: {field: 'SortableDate', ascending: true}, translationKey: 'SORT_DATEASC'},
    {value: {field: 'Subject', ascending: true}, translationKey: 'SORT_SUBJECTASC'},
    {value: {field: 'Subject', ascending: false}, translationKey: 'SORT_SUBJECTDESC'},
    {value: {field: 'Sender', ascending: true}, translationKey: 'SORT_SENDERASC'},
    {value: {field: 'Sender', ascending: false}, translationKey: 'SORT_SENDERDESC'},
    {value: {field: 'Recipient', ascending: true}, translationKey: 'SORT_RECIPIENTASC'},
    {value: {field: 'Recipient', ascending: false}, translationKey: 'SORT_RECIPIENTDESC'}
]

const _MessageListControlPanel: React.FC<{
    sorting: SortingValue, setSorting: (sorting: SortingValue) => void,
    searchInProgress: boolean,
    total?: number
} & WithTranslation>
    = ({
           sorting, setSorting,
           total,
           searchInProgress,
           t
       }) => {

    return <div className='stage_head with_panel'>
        {searchInProgress && <div className='pulse-wrapper'><PulseLoader
            color={BennoColors.red}
            size={11}
        /></div>}
        {total && <>
            <span className={'stage_head_overview_title' + (searchInProgress ? ' hidden' : '')}>
            <i>{total}</i><span id='overview_title'>{t('TXT_OVERVIEW')}</span>
        </span>
            <div className='stage_head_panel'>
                <div id='mail_overview_sort'>
                    <select tabIndex={-1} name='select_nachricht' id='select_nachricht'
                            onChange={event => setSorting(sortingOptions[event.target.selectedIndex].value)}
                            className={'input_stage_select' + (searchInProgress ? ' disabled' : '')}
                            defaultValue={sortingOptions.findIndex(option => option.value.ascending === sorting.ascending && option.value.field === sorting.field)}>
                        {sortingOptions.map(({value, translationKey}, index) => <option
                            key={value.field + '-' + value.ascending}
                            value={index}>{t(translationKey)}</option>)}
                    </select>
                </div>
            </div>
        </> || <></>}
    </div>
}

export const MessageListControlPanel = connect((rootState: RootState) => {
    return {
        searchInProgress: rootState.searchResult.loading
    }
})(withTranslation()(_MessageListControlPanel))
