import MailContentButton from "./MailContentButton";
import React, {useEffect, useState} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {ForwardMailDialog, MessageSourceDialog, PermaLinkDialog} from "./dialogs";
import {bennoBackend, createPermalink} from "../../store/thunks";
import {MailResult} from "../../types/MailResult";
import {useAppDispatch} from "../../store/store";
import {downloadBlob} from "./utils";

const _MessageControlPanel: React.FC<{
    details: MailResult,
    onPrint: (() => void),
    close: () => void
} & WithTranslation> =
    ({details, onPrint, t, close}) => {

        const [forwardDialogOpen, setForwardDialogOpen] = useState(false)
        const [permaLinkDialogOpen, setPermaLinkDialogOpen] = useState(false)
        const [messageSourceDialogOpen, setMessageSourceDialogOpen] = useState(false)

        const dispatch = useAppDispatch()

        useEffect(() => {
            document.body.classList.add('b-detail')
            return () => {
                document.body.classList.remove('b-detail')
            };
        }, [dispatch])

        function forwardEmail() {
            setForwardDialogOpen(true)
        }

        async function downloadEmail() {
            const blob = await bennoBackend.getRawMessage(details.container, details.bennoId);
            downloadBlob(blob, details.subject + '.eml')
        }

        function openPermalink() {
            dispatch(createPermalink({id: details.bennoId}))
            setPermaLinkDialogOpen(true)
        }

        function openMessageSourceDialog() {
            setMessageSourceDialogOpen(true)
        }

        return <div className='mail_content_head'>
            <div id='mail_content_head_panel'>
                {details && <>
                    <MailContentButton text={t('MSG_FORWARD')} tabIndex={11} icon='bt_wl' onClick={forwardEmail}/>
                    <ForwardMailDialog isOpen={forwardDialogOpen} close={() => {
                        setForwardDialogOpen(false);
                    }} message={details}/>
                    <MessageSourceDialog isOpen={messageSourceDialogOpen} close={() => {
                        setMessageSourceDialogOpen(false);
                    }} message={details}/>
                    <MailContentButton text={t('MSG_DOWNLOAD')} tabIndex={12} icon='bt_download'
                                       onClick={downloadEmail}/>
                    <MailContentButton text={t('MSG_SOURCE')} tabIndex={13} icon='bt_original'
                                       onClick={openMessageSourceDialog}/>
                    <MailContentButton text={t('MSG_PRINT')} tabIndex={14} icon='bt_print' onClick={onPrint}/>
                    <MailContentButton text={t('MSG_PERMALINK')} tabIndex={15} icon='bt_permalink'
                                       onClick={openPermalink}/>
                    <PermaLinkDialog isOpen={permaLinkDialogOpen} close={() => {
                        setPermaLinkDialogOpen(false)
                    }}/>
                </>}
                {/*  className={messageBody.checksum ? 'verified_ok' : ''}  */}
                <span title={t('TITLE_VERIFIED_SIGN')} id='EMail_Verified' className='verified_ok'></span>
                <MailContentButton text={''} tabIndex={16} icon='bt_close' onClick={close}/>
            </div>
        </div>;
    }

export const MessageControlPanel = withTranslation()(_MessageControlPanel)
