import {MailResult} from "../../types/MailResult";

export const downloadBlob = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)

    document.body.appendChild(link);

    link.click();

    link.parentNode!!.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function extractImageAttachments(result: MailResult) {
    return result.attachments.filter((attachment) => attachment.contentType.startsWith('image/'));
}