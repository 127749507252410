import React from "react";
import {DateSearchPanel} from "./components/DateSearchPanel";
import HeaderSearchPanel from "./components/HeaderSearchPanel";
import AttachmentSearchPanel from "./components/AttachmentSearchPanel";
import SpamSearchPanel from "./components/SpamSearchPanel";
import {ExtendedSearchButton} from "./components/ExtendedSearchButton";
import SearchFormField from "./components/SearchFormField";
import {withTranslation, WithTranslation} from "react-i18next";
import {SearchFilter} from "../../types/SearchFilter";
import {loadSearchFilter} from "../../store/SearchParametersSlice";
import {useDispatch} from "react-redux";
import Select from "react-select";
import {SavedSearch} from "../../store/SavedSearchesSlice";
import ConversationalSearchPanel from "./components/ConversationSearchPanel";

function fromStamp(date: number | undefined) {
    if (date === undefined) {
        return undefined
    }
    return new Date(date * 1000);
}

const _ExtendedSearchForm: React.FC<WithTranslation & {
    isConversation: boolean,
    setSender: (sender: string) => void,
    setReceipient: (receipient: string) => void,
    setSubject: (receipient: string) => void,
    setMessageContent: (receipient: string) => void,
    setSpamAlso: (value: boolean) => void,
    setWithAttachmentOnly: (value: boolean) => void,
    setConversationSearch: (value: boolean) => void,
    filter: SearchFilter,
    search: () => void, reset: () => void, saveSearch: () => void,
    searches?: Array<SavedSearch>
}>
    = ({
           isConversation,
           t,
           filter,
           search,
           setSender,
           setReceipient,
           setMessageContent,
           setSubject,
           reset,
           saveSearch,
           setSpamAlso,
           setWithAttachmentOnly,
           setConversationSearch,
           searches
       }) => {

    function switchUpSenderAndRecipient() {
        filter.recipient && setSender(filter.recipient)
        filter.sender && setReceipient(filter.sender)
    }

    const dispatch = useDispatch()

    return <form onSubmit={search}>

        <div id="search_query_select">

            <label className="input_label" htmlFor="search_query_sel"><span
                className="icon-database-export"></span>{t('SEARCH_SAVED_QUERYS')}</label>

            {searches === undefined && <div className='label'>{t('LABEL_LOADING')}</div>}
            {searches !== undefined &&
                <Select<{ value: SavedSearch, label: string }> className="saved-search-select react-select-control"
                                                               classNamePrefix="saved-search-select" isMulti={false}
                                                               options={searches.map(search => {
                                                                   return {label: search.name, value: search}
                                                               })}
                                                               onChange={(value) => {
                                                                   value && dispatch(loadSearchFilter(value.value))
                                                               }}/>
            }
        </div>

        <div id="search_form_container">
            {isConversation && <>
                <SearchFormField tabIndex={1} label={t('SEARCH_PARTICIPANT1')} name='participant1'
                                 setValue={setSender}
                                 value={filter.sender}
                                 submit={search}
                                 cssClass='label_ex_part1'/>
                <SearchFormField tabIndex={2} label={t('SEARCH_PARTICIPANT2')} name='participant2'
                                 setValue={setReceipient}
                                 submit={search}
                                 value={filter.recipient}
                                 cssClass='label_ex_part2'/>
            </>}

            {!isConversation && <>

                <div id="search_sender_container">

                    <SearchFormField tabIndex={1} label={t('SEARCH_SENDER')} name='sender'
                                     cssClass='label_ex_absender'
                                     setValue={setSender}
                                     submit={search}
                                     value={filter.sender}/>

                    <div id="search-switch-sender-alt">
                        <a className='hover_pointer_cursor' onClick={(evt) => {
                            switchUpSenderAndRecipient()
                        }} title={t('SEARCH_SWITCH_SENDER')}><span className="icon-shuffle2"></span></a>
                    </div>

                    <SearchFormField tabIndex={2} label={t('SEARCH_RECIPIENT')} name='recipient'
                                     cssClass='label_ex_empfaenger'
                                     submit={search}
                                     setValue={setReceipient}
                                     value={filter.recipient}/>
                </div>

            </>}

            <ConversationalSearchPanel
                onChange={setConversationSearch}
                isConversation={filter.conversationSearch || false}/>

            <SearchFormField tabIndex={3} label={t('SEARCH_SUBJECT')} name='subject'
                             cssClass='label_ex_betreff'
                             submit={search}
                             setValue={setSubject}
                             value={filter.subject}/>
            <SearchFormField tabIndex={4} label={t('SEARCH_MAILTEXT')} name='text'
                             cssClass='label_ex_volltext'
                             setValue={setMessageContent}
                             submit={search}
                             value={filter.messageContent}/>

            <DateSearchPanel startDate={fromStamp(filter.startDate)} endDate={fromStamp(filter.endDate)}/>
            <HeaderSearchPanel/>

            <AttachmentSearchPanel onChange={setWithAttachmentOnly}
                                   withAttachmentOnly={filter.withAttachmentOnly || false}/>
            <SpamSearchPanel onChange={setSpamAlso} spamAlso={filter.spamAlso || false}/>

        </div>

        <ExtendedSearchButton submitClass={isConversation ? 'search_is_konv' : 'search_is_ext'} search={search}
                              reset={reset} saveSearch={saveSearch}/>


    </form>
}

export const ExtendedSearchForm = withTranslation()(_ExtendedSearchForm)
