import * as React from "react"
import './MailContentButton.css'

type MailContentButtonProps = {
    text: string,
    icon?: string,
    tabIndex?: number,
    onClick?: () => void
}

function MailContentButton(props: MailContentButtonProps) {
    return <a id={props.icon} onClick={(evt) => {
        props.onClick && props.onClick()
    }} className='mail_content_button'
              tabIndex={props.tabIndex}>{props.text}</a>;
}

export default MailContentButton