import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {MailResult} from "../types/MailResult";

export interface MessageState {
    message?: MailResult;
    selectedByUser: boolean;
    loading: boolean;
    errors: string;
}

const initialState: MessageState = {
    loading: false,
    selectedByUser: false,
    errors: ''
}

const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.loading = payload
        },
        setErrors: (state, {payload}: PayloadAction<string>) => {
            state.errors = payload
        },
        setMessageDetails: (state, {payload}: PayloadAction<{ result: MailResult, selectedByUser: boolean }>) => {
            state.message = payload.result
            state.selectedByUser = payload.selectedByUser
        },
        unselectMessage: (state) => {
            state.selectedByUser = false
        },
        setPermaToken: (state, {payload}: PayloadAction<{ permaToken: string, bennoId: string }>) => {
            let body = state.message
            // check id of email to make sure this is not an obsolete request coming back late
            const isSameMessage = state.message?.bennoId == payload.bennoId;
            if (isSameMessage && body) {
                body.permaToken = payload.permaToken
            }
        },
        clearPermaToken: (state) => {
            let body = state.message
            if (body) {
                body.permaToken = undefined
            }
        }
    },
})

export const {
    setLoading, setErrors, setMessageDetails, setPermaToken, clearPermaToken,
    unselectMessage
} = messageSlice.actions
export const selectMessageLoadedAndSelectedByUser = (state: MessageState) => state.message && state.selectedByUser

export default messageSlice.reducer
