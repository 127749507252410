export function cloneShadowRoots(sourceElement: Element, targetElement: Element) {
    // If the source element has a Shadow DOM
    const shadowRoot = sourceElement.shadowRoot;
    if (shadowRoot !== null) {
        // Create a new Shadow Root for the target element
        const copiedShadowRoot = targetElement.attachShadow({mode: shadowRoot.mode});

        // Clone the contents of the original ShadowRoot manually
        const originalShadowContent = shadowRoot.innerHTML;

        // Create a temporary container to hold the content to copy
        const tempContainer = targetElement.ownerDocument.createElement(shadowRoot.children[0].tagName);
        tempContainer.innerHTML = originalShadowContent;

        // Clone and append the Shadow DOM content
        copiedShadowRoot.appendChild(tempContainer);

        // Recursively clone any nested Shadow DOMs within this Shadow DOM content
        const shadowChildren = copiedShadowRoot.children;
        for (let i = 0; i < shadowChildren.length; i++) {
            cloneShadowRoots(shadowRoot.children[i], shadowChildren[i]);
        }
    }

    // Now, recursively check each child node for Shadow DOMs
    const children = sourceElement.children;
    for (let i = 0; i < children.length; i++) {
        targetElement.children[i] && cloneShadowRoots(children[i], targetElement.children[i]);
    }
}