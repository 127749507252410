import BennoBackend, {
    ForwardEmailRequest,
    ForwardEmailResponse,
    PermaLinkRequest,
    Permatoken,
    SearchResult
} from "./BennoBackend";
import {User} from "../types/User";
import {SavedSearch} from "../store/SavedSearchesSlice";
import {SearchFilter} from "../types/SearchFilter";
import {SearchFormType} from "../ui/search/SearchPanel";
import {MailResult} from "../types/MailResult";
import {BennoSystemInfo} from "../types/BennoSystemInfo";
import axios from "axios"
import {User as OidcUser} from "oidc-client-ts"
import {authority, clientId} from "../Secure";
import {SortingValue} from "../store/thunks";

const restEndpoint = '/v2/'

const axiosInstance = axios.create({
    baseURL: restEndpoint,
    headers: {
        "Content-Type": "application/json",
    },
});

// axiosInstance.nstance.interceptors.request.use(
//     (config) => {
//         const token = TokenService.getLocalAccessToken();
//         if (token) {
//             // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
//             config.headers["x-access-token"] = token; // for Node.js Express back-end
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        if (err.response.status === 451) {
            alert('License is INVALID')
        }

        return Promise.reject(err);
    }
);

export function getUser() {
    const oidcStorage = localStorage.getItem(`oidc.user:${authority}:${clientId}`)
    if (!oidcStorage) {
        throw new Error('User not logged in');
    }

    return OidcUser.fromStorageString(oidcStorage);
}

export type PagedResponse = {
    currentPage: number
    totalItems: number
    totalPages: number
}

export default class RestBennoBackend implements BennoBackend {

    async getSystemInfo(): Promise<BennoSystemInfo> {
        return (await this.getResources<BennoSystemInfo>('system'))
    }

    async getUsers(): Promise<User[]> {
        return (await this.getResources<PagedResponse & { users: User[] }>('users')).users
    }

    private toQueryString(params?: { [key: string]: string }): string {
        if (!params) return '';
        return '?' + Object.entries(params)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
    }

    private async getResources<T>(path: string, params?: { [key: string]: string }) {
        return (await axiosInstance.get<T>(path + this.toQueryString(params), {
            headers: {
                ...(await this.getAuthHeaders())
            }
        })).data;
    }

    private async postResources<T>(path: string, obj: any) {
        return (await axiosInstance.post<T>(path, obj, {
            headers: {
                ...(await this.getAuthHeaders())
            }
        })).data;
    }

    private async deleteResource<T>(path: string) {
        return (await axiosInstance.delete<T>(path, {
            headers: {
                ...(await this.getAuthHeaders())
            }
        })).data;
    }

    private async getAuthHeaders() {
        return {
            'Authorization': 'Bearer ' + getUser().access_token
        }
    }

    addUser(user: User): Promise<User> {
        return new Promise((resolve) => {
            setTimeout(function () {
            }, 100)
        })
    }

    async search(container: string, filter: SearchFilter, searchForm: SearchFormType, pageSize: number, page: number, sorting: SortingValue): Promise<SearchResult> {
        const response = await axiosInstance.post<SearchResult>('search/extended',
            filter, {
                params: {
                    container: container,
                    page: page - 1,
                    pageSize: pageSize,
                    sort: sorting.field,
                    sortAsc: sorting.ascending
                },
                headers: {...(await this.getAuthHeaders())}
            });
        return response.data
    }

    async getMessageWithToken(token: string): Promise<MailResult> {
        return (await axiosInstance.get<MailResult>(`mail/permatoken/${token}`)).data
    }

    getAttachmentUrlWithToken(token: string, attachmentId: number): string {
        return restEndpoint + `mail/permatoken/${token}/${attachmentId}`
        // (await axiosInstance.get<Blob>(`mail/permatoken/${token}/${attachmentId}`, {
        //     responseType: 'blob'
        // })).data
    }

    async getMessage(container: string, bennoId: string): Promise<MailResult> {
        return (await this.getResources<MailResult>('mail/', {bennoId: bennoId, container: container}))
    }

    async getRawMessage(container: string, bennoId: string, skipUTF8Recode: boolean = true): Promise<Blob> {
        return (await axiosInstance.get<Blob>(`/mail/raw`, {
            params: {
                bennoId: bennoId,
                container: container,
                skipUTF8Recode: skipUTF8Recode
            },
            headers: {
                ...(await this.getAuthHeaders())
            },
            responseType: 'blob'
        })).data;
    }

    async getSavedSearches(userId?: string): Promise<Array<SavedSearch>> {
        return (await this.getResources<PagedResponse & {
            savedSearches: SavedSearch[]
        }>('savedsearch' + (userId ? '/user/' + userId : ''))).savedSearches
    }

    async addSavedSearch(name: string, searchFilter: SearchFilter): Promise<SavedSearch> {
        return (await this.postResources<SavedSearch>('savedsearch', {
            searchFilter: searchFilter,
            name: name
        }))
    }

    async deleteSavedSearch(searchId: string): Promise<void> {
        return await this.deleteResource('savedsearch/' + searchId)
    }

    async forwardEmail(request: ForwardEmailRequest): Promise<ForwardEmailResponse> {
        return await this.postResources<ForwardEmailResponse>('/forward', request)
    }

    async createPermaToken(request: PermaLinkRequest): Promise<Permatoken> {
        return await this.postResources<Permatoken>('/permatoken', request)
    }

    async getAttachment(bennoId: string, container: string, attachmentId: number) {
        return (await axiosInstance.get<Blob>(`/mail/attachment`, {
            params: {
                bennoId: bennoId,
                attachmentId: attachmentId,
                container: container
            },
            headers: {
                ...(await this.getAuthHeaders())
            },
            responseType: 'blob'
        })).data
    }

    saveUser(user: User): Promise<User> {
        return new Promise((resolve, error) => {
        })
    }

    async getUser(userId: string): Promise<User> {
        throw Error('User not found')
    }

}
