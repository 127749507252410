import * as React from "react";
import {useEffect} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {Dialog} from "./dialogs";
import {connect} from "react-redux";
import {getBennoSystemInfo} from "../store/thunks";
import {BennoSystemInfo} from "../types/BennoSystemInfo";
import {RootState, useAppDispatch} from "../store/store";
import packageData from '../../package.json'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        height: '90%',
        width: '700px',
        transform: 'translate(-50%, -50%)'
    }
}

export const _BennoInfoDialog: React.FC<WithTranslation & {
    onClose: () => void,
    bennoSystemInfo?: BennoSystemInfo,
    bennoSystemInfoLoading: boolean
}> =
    ({t, onClose, bennoSystemInfo, bennoSystemInfoLoading}) => {

        const dispatch = useAppDispatch()

        useEffect(() => {
            dispatch(getBennoSystemInfo())
        }, [dispatch])

        return <Dialog isOpen={true} title={t('TXT_SYSTEMINFO')}
                       buttons={[{text: 'Ok', isMain: true, class: 'ui-button-fullwidth'}]} close={onClose}
                       modalStyle={customStyles}>
            {bennoSystemInfoLoading && <div className='loader'></div>}
            {!bennoSystemInfoLoading && bennoSystemInfo && <>
                <div><b>Benno MailArchiv Version {packageData.version}</b></div>
                <div>Build: {packageData.version}</div>
                <br/>
                <table>
                    <tbody>
                    <tr>
                        <td colSpan={2}><br/></td>
                    </tr>
                    <tr>
                        <td colSpan={2}><b>{t('TXT_LICINFO')}</b></td>
                    </tr>

                    <tr>
                        <td>{t('TXT_LICTYPE')}:</td>
                        <td>{bennoSystemInfo.license?.licenseType}</td>
                    </tr>
                    <tr>
                        <td>{t('TXT_LICVALID')}:</td>
                        <td>{bennoSystemInfo.license?.licenseExpires}</td>
                    </tr>
                    <tr>
                        <td>TODO: Hostinfo:</td>
                        <td>
                            {/*<a target="_blank" style={{fontSize: '100%', textDecoration: 'underline'}}*/}
                            {/*   href={"https://www.benno-mailarchiv.de/lizenz?hostinfo=" + bennoSystemInfo.bennoSystemInformation.}>{bennoSystemInfo.licence.hostInfo}</a>*/}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}><br/></td>
                    </tr>
                    <tr>
                        <td colSpan={2}><b>{t('TXT_SYSTEMINFO')}</b></td>
                    </tr>
                    <tr>
                        <td>{t('TXT_HOSTNAME')}:</td>
                        <td>{bennoSystemInfo.system?.myHostname}</td>
                    </tr>
                    <tr>
                        <td>{t('TXT_ADDRESS')}:</td>
                        <td>{bennoSystemInfo.system?.myAddress}</td>
                    </tr>
                    <tr>
                        <td>{t('TXT_CONTAINERCOUNT')}:</td>
                        <td>{bennoSystemInfo.system?.archiveCount}</td>
                    </tr>
                    <tr>
                        <td>{t('TXT_COREVERSION')}:</td>
                        <td>{bennoSystemInfo.system?.coreVersion}</td>
                    </tr>
                    <tr>
                        <td>{t('TXT_RESTVERSION')}:</td>
                        <td>{bennoSystemInfo.system?.restVersion}</td>
                    </tr>
                    <tr>
                        <td>{t('TXT_MAILDOMAIN')}:</td>
                        <td>{bennoSystemInfo.system?.mailDomain}</td>
                    </tr>
                    </tbody>
                </table>
            </>}
            <hr/>
            <div>
                © 2006-2020 LWsystems GmbH &amp; Co. KG<br/>
                All Rights reserved.<br/>
                https://www.benno-mailarchiv.de<br/>
                <br/>
                All content on this website (including text, images, and any other original<br/>
                works), unless otherwise noted, is licensed under a Creative Commons License.<br/>
            </div>
            <div>
                <a rel="license" href="h{t('p')}://creativecommons.org/licenses/by-nc-sa/3.0/"><img
                    alt="Creative Commons Lizenzvertrag" style={{borderWidth: 0}}
                    src="img/by-nc-sa_3.0.png"/></a><br/>
                <span property="dct:title">Benno MailArchiv WebApp</span> von <span
                property="cc:attributionName">LWsystems GmbH &amp; Co. KG</span> steht unter einer
                <a rel="license" target="_blank" href="h{t('p')}://creativecommons.org/licenses/by-nc-sa/3.0/de/">Creative
                    Commons Namensnennung-Nicht-kommerziell-Weitergabe unter gleichen Bedingungen 3.0 Unported
                    Lizenz</a>.
            </div>

        </Dialog>
    }

export const BennoInfoDialog = withTranslation()(connect((state: RootState) => {
    return {
        bennoSystemInfo: state.bennoData.bennoSystemInfo,
        bennoSystemInfoLoading: state.bennoData.bennoSystemInfoLoading
    }
})(_BennoInfoDialog))
